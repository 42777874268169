import React, { useState } from "react"
// import { Link } from "gatsby"
import styled from "styled-components/macro"
import { Waypoint } from "react-waypoint"
import Utlis from "../consts/Utlis"
import Devices from "../consts/Devices"
import Colors from "../consts/Colors"
import { useSpring, animated } from "react-spring"
import EnterSection from "../components/enter-section/EnterSection"
import Modal from "react-modal"
import SignUpModal from "../components/signup-modal/SignupModal"
import ConfirmModal from "../components/confirm-modal/ConfirmModal"
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"

import Hero from "../components/hero/Hero"
import Quote from "../components/quote/Quote"

import one from "../images/1.jpg"
import two from "../images/2.jpg"
import three from "../images/3.jpg"
import Facts from "../images/facts.png"

let imagesArr = [one, two, one, one]

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 100,
  },
}

const IndexPage = () => {
  if (typeof window === `undefined`) {
    return <></>
  }
  const [active, setActive] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(
    window.localStorage.getItem("onboard") == "true"
  )

  const appear = useSpring({
    delay: 1500,
    from: { opacity: 0, transform: "scale(0)" },
    to: { opacity: 1, transform: "scale(1)" },
  })

  const appear2 = useSpring({
    delay: 1500,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  return (
    <>
      <Hero
        openModal={() => {
          setModalIsOpen(true)
        }}
      ></Hero>
      <Copy>
        <Head>
          <h4>1 of 488 </h4>
          <h3>UNIQUE BOTTLES</h3>
        </Head>
        <Blurb>
          <p>
            Goldfinch single malts are acclaimed for their incredible
            smoothness, richness and intriguing complexity.
          </p>
          <p>
            In pursuit of the exceptional, we distil Goldfinch’s spirit in
            Scotland’s tallest stills. Their long copper necks – the same height
            as an adult male giraffe – allow only the very finest vapours to
            rise to the top for a wonderfully pure and fragrant spirit, bursting
            with fruity, floral complexity. As we bring this delicate spirit to
            maturity, we call upon the Distillery’s generations of expertise in
            wood.
          </p>
          <p>
            Our designer casks, created bespoke from hand-selected slow-growth
            American oak, have become a byword for excellence. We pioneered the
            art of extra-maturation – now widely used throughout the whisky
            industry – and have been experimenting with innovative wood finishes
            ever since. Today, our Whisky Creation Team, led by our acclaimed
            Director of Distilling, Whisky Creation & Whisky Stocks Dr John
            Smith, continues to challenge the bounds of possibility in single
            malt.
          </p>
          <More>
            <Link
              className="test6"
              to="enter"
              spy={true}
              smooth={true}
              duration={500}
            >
              <span>Find out how much and enter ballot</span>
              <i className="fa fa-chevron-down"></i>
            </Link>
          </More>
          <FactsComp src={Facts}></FactsComp>
        </Blurb>
      </Copy>
      <animated.div style={{ ...appear2, delay: 1000 }}>
        <Quote></Quote>
      </animated.div>
      <Images>
        <Waypoint
          onEnter={() => {
            setActive(true)
          }}
          onExit={() => {
            setActive(false)
          }}
        />
        <animated.div
          className="img"
          style={active ? appear : {}}
        ></animated.div>
        <Col>
          <animated.div
            className="bg1"
            style={active ? { ...appear, delay: 3000 } : {}}
          ></animated.div>
          <animated.div
            className="bg3"
            style={active ? appear : {}}
          ></animated.div>
        </Col>
        <animated.div
          className="img"
          style={active ? appear : {}}
        ></animated.div>
      </Images>
      <Element name="enter" className="element">
        <EnterSection
          openModal={() => {
            setModalIsOpen(true)
          }}
        ></EnterSection>
      </Element>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        {typeof window !== `undefined` &&
          (window.localStorage.getItem("onboard") == "true" ? (
            <ConfirmModal
              close={() => {
                setModalIsOpen(false)
              }}
            />
          ) : (
            <SignUpModal />
          ))}
      </Modal>
    </>
  )
}

const FactsComp = styled.img`
  max-width: 100%;
  margin-top: 3rem;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0.5em;
  > div {
    height: 100%;
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin: 0 0 1em;
    border-radius: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Images = styled.div`
  width: 100%;
  padding-bottom: 30px;
  @media ${Devices.laptop} {
    height: 100vh;
    display: flex;
    flex-direction: row;
  }

  .bg1 {
    background-image: ${`url(${one})`};
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .bg2 {
    background-image: ${`url(${two})`};
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .bg3 {
    background-image: ${`url(${three})`};
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  > .img {
    width: 100%;
    background-image: ${`url(${two})`};
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 5px;
    height: 50vh;
    @media ${Devices.laptop} {
      height: 100%;
      margin: 0.5em;
    }
  }
`

const Copy = styled.div`
  padding: 2em 30px;
  @media ${Devices.laptop} {
    display: flex;
    justify-content: space-between;
    padding: 8em 80px;
    max-width: 1600px;
    margin: 0 auto;
  }
`
const Head = styled.div`
  margin-bottom: 2em;
  @media ${Devices.laptop} {
    position: relative;
    padding-left: 100px;
  }

  h3,
  h4 {
    color: ${Colors.darkGrey};
  }

  h4 {
    ${Utlis.fontSize(16, 33, "1300px")}
  }

  h3 {
    ${Utlis.fontSize(20, 80, "1300px")}
    max-width: 475px;
    font-weight: 500;
    font-family: "Nanum Myeongjo", serif;
    padding-bottom: 1em;
    @media ${Devices.laptop} {
      padding-bottom: 0;
    }
  }

  &:after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background: ${Colors.red};
    @media ${Devices.laptop} {
      position: absolute;
      top: 100px;
      left: 0;
    }
  }
`

const Blurb = styled.div`
  @media ${Devices.laptop} {
    max-width: 680px;
    padding-top: 4em;
  }

  p {
    ${Utlis.fontSize(15, 19, "1024px")}
    font-weight: 300;
    + p {
      margin-top: 1em;
    }
  }
`

const More = styled.div`
  margin-top: 2em;
  display: flex;
  cursor: pointer;
  span {
    ${Utlis.fontSize(15, 19, "1024px")}
    font-weight: 600;
    color: #171717;
  }

  i {
    color: ${Colors.red};

    margin-left: 10px;
  }
`

export default IndexPage
