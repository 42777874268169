import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Utils from "../../consts/Utlis"
import Countdown from "react-countdown-now"

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <React.Fragment>
      <Value>
        <h3>{days}</h3>
        <span>Days</span>
      </Value>
      <Value>
        <h3>{hours}</h3>
        <span>Hours</span>
      </Value>
      <Value>
        <h3>{minutes}</h3>
        <span>Minutes</span>
      </Value>
      <Value>
        <h3>{seconds}</h3>
        <span>Seconds</span>
      </Value>
    </React.Fragment>
  )
}

const Timer = ({ className }) => {
  return (
    <Wrapper className={className}>
      <SubTitle>DRAW CLOSES IN</SubTitle>
      <Clock>
        <Countdown
          date={Date.now() + 100000000}
          renderer={renderer}
        ></Countdown>
      </Clock>
    </Wrapper>
  )
}

const Wrapper = styled.h4``

const SubTitle = styled.div`
  font-family: "Nanum Myeongjo", serif;
  ${Utils.fontSize(16, 18, "1200px")}
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
`

const Clock = styled.div`
  display: flex;
  justify-content: space-between;
`

const Value = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2vw;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  &:first-child {
    border-left: 0px;
  }
  h3,
  span {
    color: #fff;
    font-family: "Nanum Myeongjo", serif;
    text-align: center;
  }
  h3 {
    ${Utils.fontSize(16, 60, "1200px")}
  }
  span {
    ${Utils.fontSize(12, 16, "1200px")}
  }
`

export default Timer
