import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Utils from "../../consts/Utlis"
import Colors from "../../consts/Colors"
import Button from "../button/Button"
import { Link } from "gatsby"

const SignupModal = ({}) => {
  return (
    <Wrapper>
      <Title>One more thing...</Title>
      <Blurb>
        You must first registered to enter this ballot, to confirm your entery
        please complete your details.
        <br />
        <br />
        We’ll select winners randomly and notify you within 3 hours of the draw
        finishing.
      </Blurb>
      <Link to="/register">
        <Button>
          <span>Continue entry</span>
        </Button>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Title = styled.div`
  ${Utils.fontSize(18, 33, "1200px")}
  color: ${Colors.black};
  text-align: center;
  font-family: "Nanum Myeongjo", serif;
`

const Blurb = styled.div`
  ${Utils.fontSize(16, 18, "1200px")}
  text-align: center;
  font-family: "Work Sans", sans-serif;
  padding: 0 1rem;
  margin: 0 auto;
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
`

export default SignupModal
