import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Utlis from "../../consts/Utlis"
import Devices from "../../consts/Devices"

const Quote = ({}) => {
  return (
    <Wrapper>
      <h4>
        "I sampled casks from all over the north of Scotland but this particular
        cask had superior sensory and palate qualities – it has the wonderful
        flavours of a Sherry cask which is filled with whisky for the first
        time."
      </h4>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2em 30px 5em;
  @media ${Devices.laptop} {
    padding: 2em 30px 10em;
  }
  h4 {
    text-align: center;
    ${Utlis.fontSize(16, 33, "1300px")}
    font-weight: 500;
    font-family: "Nanum Myeongjo", serif;
    max-width: 950px;
    margin: 0 auto;
    color: #2b2b2b;
  }
`

export default Quote
