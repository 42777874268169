import React, { useRef, useState } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Utils from "../../consts/Utlis"
import Devices from "../../consts/Devices"

import Button from "../button/Button"
import Timer from "../timer/Timer"

import BGImage from "../../images/head.jpg"
import { blue } from "ansi-colors"

const Hero = ({ openModal }) => {
  const appear = useSpring({
    delay: 1500,
    from: { opacity: 0, filter: `blur(20px)` },
    to: { opacity: 1, filter: `blur(0px)` },
  })

  return (
    <Wrapper>
      <Title>GOLDFINCH</Title>
      <Button onClick={openModal}>
        <span>Enter Ballot</span>
      </Button>

      <TimerComp />

      <Background style={appear}></Background>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${Colors.black};
  min-height: calc(80vh);
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  min-height: 790px;
  border-radius: 0 0 15px 15px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 20vh;
    background: rgba(255, 255, 255, 0.2);
    top: 15px;
    left: 50%;
  }
`

const Title = styled.h1`
  color: #ffffff;
  ${Utils.fontSize(28, 133, "1200px")}
  text-align: center;
  font-family: "Nanum Myeongjo", serif;
`

const TimerComp = styled(Timer)`
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  margin-bottom: 15px;
  padding: 1em;
  border-radius: 4px;

  @media ${Devices.laptop} {
    bottom: 25px;
  }
`

const Background = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${BGImage});
  background-size: cover !important;
  background-position: center !important;
  z-index: -1;
`

export default Hero
