import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Utils from "../../consts/Utlis"
import Colors from "../../consts/Colors"
import Button from "../button/Button"

const ConfirmModal = ({ close }) => {
  return (
    <Wrapper onClick={close}>
      <Title>Congratulations</Title>
      <Blurb>
        You have successfully entered the ballot. <br />
        <br />
        You will receive an email with the ballot results as soon as they are
        drawn. Good Luck!
      </Blurb>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Title = styled.div`
  ${Utils.fontSize(18, 33, "1200px")}
  color: ${Colors.black};
  text-align: center;
  font-family: "Nanum Myeongjo", serif;
`

const Blurb = styled.div`
  ${Utils.fontSize(16, 18, "1200px")}
  text-align: center;
  font-family: "Work Sans", sans-serif;
  padding: 0 1rem;
  margin: 0 auto;
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
`

export default ConfirmModal
